import React from 'react'

import { Auth } from 'aws-amplify'

const SignOut = () => {
  const signOut = async () => {
    try {
      await Auth.signOut()
    } catch (error) {
      console.log('error signing out: ', error)
    }
  }

  return (
    <div>
        <button onClick={() => signOut()}>Sign Out</button>
    </div>
  )
}

export default SignOut
