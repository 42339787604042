/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: process.env.REACT_APP_aws_project_region,
  aws_cognito_region: process.env.REACT_APP_aws_cognito_region,
  aws_user_pools_id:
    process.env.REACT_APP_environment_type === 'clinicvu'
      ? process.env.REACT_APP_clinicvu_aws_user_pools_id
      : process.env.REACT_APP_biovu_aws_user_pools_id,
  aws_cognito_identity_pool_id:
    process.env.REACT_APP_environment_type === 'clinicvu'
      ? process.env.REACT_APP_clinicvu_aws_cognito_identity_pool_id
      : process.env.REACT_APP_biovu_aws_cognito_identity_pool_id,
  aws_user_pools_web_client_id:
    process.env.REACT_APP_environment_type === 'clinicvu'
      ? process.env.REACT_APP_clinicvu_aws_user_pools_web_client_id
      : process.env.REACT_APP_biovu_aws_user_pools_web_client_id,
  oauth: {},
  aws_cognito_login_mechanisms: ['EMAIL', 'PREFERRED_USERNAME'],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: []
  },
  aws_cloud_logic_custom: [
    {
      name: 'AdminQueries',
      endpoint: 'https://1narxp5tgh.execute-api.ca-central-1.amazonaws.com/dev',
      region: 'ca-central-1'
    }
  ]
}

export default awsmobile
