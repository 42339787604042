import React from 'react'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import PropTypes from 'prop-types'

const CameraSelector = ({
  setShowCameras,
  cameraSelected,
  toggleVideoSession,
  foundWebcams
}) => {
  return (
    <Select
      open={true}
      onClose={() => setShowCameras(false)}
      onOpen={() => setShowCameras(true)}
      value={cameraSelected}
      onChange={toggleVideoSession}
    >
      {foundWebcams
        ? Object.keys(foundWebcams).map((item) => (
            <MenuItem key={item} value={item}>
              {foundWebcams[item]}
            </MenuItem>
        ))
        : null}
      <MenuItem value={'off'}>Off</MenuItem>
    </Select>
  )
}

CameraSelector.propTypes = {
  setShowCameras: PropTypes.func,
  cameraSelected: PropTypes.string,
  toggleVideoSession: PropTypes.func,
  foundWebcams: PropTypes.object
}

export default React.memo(CameraSelector)
