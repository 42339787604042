import React from 'react'
import Alert from '@mui/material/Alert'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import moment from 'moment'
import { patientModalStyles } from '@utils/constants'
import Button from '@mui/material/Button'
import PropTypes from 'prop-types'
import LooksOneRoundedIcon from '@mui/icons-material/LooksOneRounded'
import LooksTwoRoundedIcon from '@mui/icons-material/LooksTwoRounded'
import styles from './NewNotesModal.module.scss'

const NewNotesModal = ({
  isOpenNotesModal,
  closePatientNotesModal,
  me,
  patientNoteText,
  setPatientNoteText,
  patientIsReviewed,
  setPatientIsReviewed,
  handleNoteSubmit
}) => {
  return (
    <Modal
      open={isOpenNotesModal}
      onClose={closePatientNotesModal}
      aria-labelledby="Patient New Notes Modal"
      aria-describedby="This modal allows the clinician to add notes for the current patient"
    >
      <Box sx={patientModalStyles}>
        <Typography id="new-notes-modal__title">Add Note</Typography>
        <Typography id="new-notes-modal__sub" variant="h6" component="h2">
          {me?.last_name}, {me?.designation} |{' '}
          {moment().format('dddd MMMM YYYY')} | {moment().format('h:mm a')}
        </Typography>
        <hr />
        <div className={styles.modal__row}>
          <LooksOneRoundedIcon fontSize="large" />
          <TextField
            id="filled-basic"
            label="Enter here"
            variant="filled"
            multiline
            sx={{ width: '95%', marginLeft: 1 }}
            onChange={(e) => setPatientNoteText(e.target.value)}
          />
        </div>
        {patientNoteText?.length < 2 && (
          <Alert severity="error" sx={{ marginTop: 1 }}>
            A minimum of 2 characters is required for the new note.
          </Alert>
        )}
        <hr />
        <div className={styles['modal__row horizontal']}>
          <div className={styles.modal__row}>
            <LooksTwoRoundedIcon fontSize="large" />
            <Typography sx={{ width: '90%', marginLeft: 1 }}>
              Have you reviewed patient data and all flags?
            </Typography>
          </div>
          <div>
            <Button
              sx={{ marginLeft: 5 }}
              variant={patientIsReviewed === true ? 'contained' : 'outlined'}
              onClick={() => setPatientIsReviewed(true)}
            >
              Yes
            </Button>
            <Button
              sx={{ marginLeft: 1 }}
              variant={patientIsReviewed === false ? 'contained' : 'outlined'}
              onClick={() => setPatientIsReviewed(false)}
            >
              No
            </Button>
          </div>
          {patientIsReviewed == null && (
            <Alert severity="error" sx={{ marginTop: 1 }}>
              Please select whether the recent patient data has been reviewed.
            </Alert>
          )}
        </div>
        <div className={styles.modal__buttons}>
          <Button
            variant="outlined"
            color="error"
            onClick={closePatientNotesModal}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            sx={{ marginLeft: 1 }}
            disabled={patientIsReviewed == null || patientNoteText?.length < 2}
            onClick={handleNoteSubmit}
          >
            Publish
          </Button>
        </div>
      </Box>
    </Modal>
  )
}

NewNotesModal.propTypes = {
  isOpenNotesModal: PropTypes.bool,
  setIsOpenNotesModal: PropTypes.func,
  me: PropTypes.object,
  patientNoteText: PropTypes.string,
  patientIsReviewed: PropTypes.bool,
  setPatientIsReviewed: PropTypes.func,
  handleNoteSubmit: PropTypes.func,
  closePatientNotesModal: PropTypes.func,
  setPatientNoteText: PropTypes.func
}

export default NewNotesModal
