import React from 'react'
import useStyles from './VideoBox.style'
import { UserVideo } from '@shared/user-video/UserVideo'
import PropTypes from 'prop-types'

const VideoBox = ({
  publisher,
  handleMainVideoStream,
  subscribers,
  mainStreamManager
}) => {
  const classes = useStyles()

  return (
    <div id="video-container" className={classes.video}>
      <div id="videos-box">
        {publisher
          ? (
          <div onClick={() => handleMainVideoStream(publisher)}>
            <UserVideo streamManager={publisher} isPublisher={true} />
          </div>
            )
          : null}
        {subscribers?.map((sub, i) => (
          <div key={i} onClick={() => handleMainVideoStream(sub)}>
            <UserVideo streamManager={sub} isPublisher={true} />
          </div>
        ))}
      </div>
      {publisher && (
        <UserVideo
          isPublisher={false}
          streamManager={
            mainStreamManager != null ? mainStreamManager : publisher
          }
        />
      )}
    </div>
  )
}

VideoBox.propTypes = {
  publisher: PropTypes.object,
  handleMainVideoStream: PropTypes.func,
  subscribers: PropTypes.array,
  mainStreamManager: PropTypes.object,
  imageEditorState: PropTypes.bool,
  recordingMode: PropTypes.bool,
  sendPointer: PropTypes.func,
  sendStartDrawing: PropTypes.func,
  sendStopDrawing: PropTypes.func,
  sendDrawing: PropTypes.func,
  sendResetDrawing: PropTypes.func,
  takePhoto: PropTypes.bool,
  setTakePhoto: PropTypes.func,
  addImagesArray: PropTypes.func,
  currentImage: PropTypes.string,
  setImageEditorState: PropTypes.func,
  setDrawerOpen: PropTypes.func
}

export default React.memo(VideoBox)
