import { makeStyles } from '@mui/styles'

export default makeStyles(() => ({
  video: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))
