// TODO: update the useMediaQuery hook param to automatically select the width
import { CurrentPortalContext } from '@context/CurrentPortalContext'
import MenuIcon from '@mui/icons-material/Menu'
import AppBar from '@mui/material/AppBar'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import useMediaQuery from '@mui/material/useMediaQuery'
import Sidebar from '@shared/sidebar/Sidebar'
import PropTypes from 'prop-types'
import React, { useContext, useState } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import styles from './AppRouter.module.scss'
import BiovuRoutes from './BiovuRoutes'
import ClinicvuRoutes from './ClinicvuRoutes'

const appBarStyles = {
  backgroundColor: '#FFF'
}

const AppRouter = ({ user, jwt }) => {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false)
  const isMobileView = useMediaQuery('(max-width:950px)')
  const { handlePortalChange, currentPortal } = useContext(CurrentPortalContext)

  return (
    <Router>
      <div className={styles['app-container']}>
        {isMobileView && (
          <AppBar sx={appBarStyles}>
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                aria-label="menu"
                sx={{ mr: 2, color: '#3494b6' }}
                onClick={() => setDrawerIsOpen(true)}
              >
                <MenuIcon />
              </IconButton>
              {process.env.REACT_APP_environment_type === 'clinicvu' && (
                <img
                  className={styles.appbar__logo}
                  src="/assets/clinicvu-logo.svg"
                  alt="Logo"
                />
              )}
              {process.env.REACT_APP_environment_type === 'biovu' && (
                <img
                  className={styles.appbar__logo}
                  src="/assets/biovu-logo.svg"
                  alt="Logo"
                />
              )}
            </Toolbar>
          </AppBar>
        )}

        {isMobileView
          ? (
          <Drawer
            anchor="left"
            open={drawerIsOpen}
            onClose={() => setDrawerIsOpen(false)}
          >
            <Sidebar
              currentPortal={currentPortal}
              handlePortalChange={handlePortalChange}
            />
          </Drawer>
            )
          : (
          <div className={styles['sidebar-container']}>
            <Sidebar
              currentPortal={currentPortal}
              handlePortalChange={handlePortalChange}
            />
          </div>
            )}

        <div className={styles['routes-container']}>
          {process.env.REACT_APP_environment_type === 'clinicvu'
            ? (
            <ClinicvuRoutes />
              )
            : null}
          {process.env.REACT_APP_environment_type === 'biovu'
            ? (
            <BiovuRoutes />
              )
            : null}
        </div>
      </div>
    </Router>
  )
}

AppRouter.propTypes = {
  user: PropTypes.object,
  jwt: PropTypes.string
}

export default AppRouter
