// todo: add phone number to the object that represents our patients in the backend
// todo: re-add the edit and delete buttons with proper functionality.

import React, { useContext, useEffect, useState } from 'react'

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material'
import styles from './AllPatientsTable.module.scss'

import {
  formatCarePlan,
  formatPhoneNumbers,
  getAlerts,
  epochToDDMMYY,
  capitalize,
  getAlertCounts
} from '@utils/functions'
import { CurrentPortalContext } from '@context/CurrentPortalContext'
import { UserContext } from '@context/UserContext'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import ClinicvuService from 'services/Clinicvu/Clinicvu.service'
import PatientAlerts from './patient-alerts/PatientAlerts'

const AllPatientsTable = () => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [searchTerm, setSearchTerm] = useState('')
  const [currentPatients, setCurrentPatients] = useState([])

  const navigate = useNavigate()

  const { currentPortal } = useContext(CurrentPortalContext)
  const { me } = useContext(UserContext)

  const currentPortalName =
    process.env.REACT_APP_environment_type === 'clinicvu'
      ? me?.portals[currentPortal].portal?.name
      : null

  const displayEWSStatus = (newsScores) => {
    if (!newsScores || newsScores?.length === 0) return 'NA'
    const newsScore = newsScores[0]
    let value = 'Green'
    if (
      (newsScore.bt_score === 3) |
      (newsScore.hr_score === 3) |
      (newsScore.sp_score === 3) |
      (newsScore.spo2_score === 3) |
      (newsScore.rr_score === 3)
    ) {
      value = 'Orange'
    }
    if (newsScore.newsScore >= 5) {
      value = 'Red'
    }
    return value
  }

  const { data: patients } = useQuery(
    ['patients', currentPortalName, searchTerm],
    async () => {
      const res = await ClinicvuService.getBiovuUsersOnPortal(
        currentPortalName,
        searchTerm
      )
      if (res.status !== 200) {
        throw new Error(
          `Was not able to fetch all patients on portal ${currentPortalName}`
        )
      }
      return res.data
    },
    {
      enabled: !!me
    }
  )

  useEffect(() => {
    // we want to sort first by the number of out of range alerts, then by the number of missing alerts
    patients?.sort((patient1, patient2) => {
      const patient1Counts = getAlertCounts(patient1)
      const patient2Counts = getAlertCounts(patient2)
      if (patient1Counts.outOfRange < patient2Counts.outOfRange) {
        return 1
      } else if (patient1Counts.outOfRange > patient2Counts.outOfRange) {
        return -1
      } else if (patient1Counts.missing < patient2Counts.missing) {
        return 1
      }
      return -1
    })

    setCurrentPatients(
      patients?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) ||
        []
    )
  }, [patients, page, rowsPerPage])

  const handlePageChange = (e, newPage) => {
    setPage(newPage)
  }

  const handleRowsPerPageChange = (e) => {
    const newRowsPerPage = parseInt(e.target.value, 10)
    setRowsPerPage(newRowsPerPage)
    setPage(0)
  }

  return (
    <div className={styles.container}>
      <div
        className={`${styles.header} ${
          process.env.REACT_APP_environment_type === 'biovu'
            ? styles.header__top
            : ''
        }`}
      >
        {process.env.REACT_APP_environment_type === 'clinicvu'
          ? 'Patients'
          : 'Users'}
      </div>
      {process.env.REACT_APP_environment_type === 'clinicvu' && (
        <div className={styles['top-section__search']}>
          <img
            src="/assets/svgs/search.svg"
            alt="Search"
            className={styles['top-section__search-icon']}
          />
          <input
            type="text"
            placeholder="Search"
            className={styles['top-section__search-input']}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      )}
      <TableContainer component={Paper} sx={{ width: '100%' }}>
        <Table
          stickyHeader
          aria-label="All Patients Table"
          className={styles.table}
        >
          <TableHead className={styles.table__head}>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Date of Birth</TableCell>
              <TableCell>Sex</TableCell>
              {process.env.REACT_APP_environment_type === 'clinicvu'
                ? (
                <>
                  <TableCell>Care Plan(s)</TableCell>
                  <TableCell>Needs Review</TableCell>
                  <TableCell>EWS Status</TableCell>
                </>
                  )
                : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {process.env.REACT_APP_environment_type === 'clinicvu' &&
              currentPatients.map((patient, index) => (
                <TableRow
                  className={styles.tablerow}
                  key={patient.biovu_user.email + '-' + index}
                  onClick={() => navigate(`/patient/${patient.id}`)}
                >
                  <TableCell>
                    <div className={styles.username}>
                      <img
                        src="assets/user-icon.jpeg"
                        alt={`User ${patient.biovu_user.name}`}
                        className={styles['user-icon']}
                      />
                      <span className={styles.username__text}>
                        {patient.biovu_user.first_name +
                        patient.biovu_user.last_name
                          ? patient.biovu_user.first_name +
                            ' ' +
                            patient.biovu_user.last_name
                          : null}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell>{patient.biovu_user.email}</TableCell>
                  <TableCell>
                    {patient.biovu_user.phone
                      ? formatPhoneNumbers(patient.biovu_user.phone)
                      : null}
                  </TableCell>
                  <TableCell>
                    {epochToDDMMYY(patient.biovu_user.birth_date_at)}
                  </TableCell>
                  <TableCell>{capitalize(patient.biovu_user.sex)}</TableCell>
                  <TableCell>{formatCarePlan(patient)}</TableCell>
                  <TableCell>
                    <PatientAlerts alerts={getAlerts(patient)} />
                  </TableCell>
                  <TableCell>
                    <span
                      className={`${
                        styles[
                          displayEWSStatus(patient.news_scores)?.toLowerCase()
                        ]
                      } ${styles.transparent}`}
                    >
                      {displayEWSStatus(patient.news_scores)}
                    </span>
                  </TableCell>
                </TableRow>
              ))}
            {process.env.REACT_APP_environment_type === 'biovu' &&
              currentPatients.map((patient, index) => (
                <TableRow
                  className={styles.tablerow}
                  key={patient.email + '-' + index}
                  onClick={() => navigate(`/user/${patient.id}`)}
                >
                  <TableCell>
                    <div className={styles.username}>
                      <img
                        src="assets/user-icon.jpeg"
                        alt={`User ${patient.name}`}
                        className={styles['user-icon']}
                      />
                      <span className={styles.username__text}>
                        {patient.first_name + patient.last_name
                          ? patient.first_name + ' ' + patient.last_name
                          : null}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell>{patient.email}</TableCell>
                  <TableCell>
                    {patient.phone ? formatPhoneNumbers(patient.phone) : null}
                  </TableCell>
                  <TableCell>{epochToDDMMYY(patient.birth_date_at)}</TableCell>
                  <TableCell>{capitalize(patient.sex)}</TableCell>
                </TableRow>
              ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleRowsPerPageChange}
                page={page}
                onPageChange={handlePageChange}
                count={patients?.length || 0}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  )
}

export default AllPatientsTable
