import React from 'react'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import { patientModalStyles } from '@utils/constants'
import PropTypes from 'prop-types'

const ViewAlertModal = ({ selectedAlert, setSelectedAlert }) => {
  return (
    <Modal
      open={!!selectedAlert}
      onClose={() => setSelectedAlert(null)}
      aria-labelledby="Alert Modal"
      aria-describedby="This modal contains the detailed information regarding the currently selected alert"
    >
      <Box sx={patientModalStyles}>
        <Typography variant="h4" id="note-details-modal__title">
          Alert Details
        </Typography>
        <Typography
          id="note-details-modal__sub-date"
          variant="h6"
          component="h2"
        >
          Created at:{' '}
          {`${moment(selectedAlert?.created_at).format(
            'MMMM Do YYYY, h:mm:ss a'
          )}`}
        </Typography>
        <hr />
        <Typography sx={{ width: '90%' }}>
          Alert details: {`${selectedAlert?.issue}`}
        </Typography>
        <Typography sx={{ width: '90%' }}>
          Requirement frequency:{' '}
          {`${selectedAlert?.requirement?.frequency}x per ${selectedAlert?.requirement?.period}`}
        </Typography>
        <Typography sx={{ width: '90%' }}>
          Requirement care plan:{' '}
          {`${selectedAlert?.biovu_users_on_portals_on_care_plan?.care_plan?.name}`}
        </Typography>
        {selectedAlert?.requirement?.surveys_on_care_plans
          ? (
          <Typography sx={{ width: '90%' }}>
            Survey detail:{' '}
            {`${selectedAlert?.requirement?.surveys_on_care_plans?.survey?.name}`}
          </Typography>
            )
          : null}
        {selectedAlert?.requirement?.normal_range_min
          ? (
          <Typography sx={{ width: '90%' }}>
            Minimum Threshold:{' '}
            {`${selectedAlert?.requirement?.normal_range_min}`}
          </Typography>
            )
          : null}
        {selectedAlert?.requirement?.normal_range_max
          ? (
          <Typography sx={{ width: '90%' }}>
            Maximum Threshold:{' '}
            {`${selectedAlert?.requirement?.normal_range_max}`}
          </Typography>
            )
          : null}
        {selectedAlert?.requirement?.normal_range_min?.sp
          ? (
          <Typography sx={{ width: '90%' }}>
            Minimum Systolic Threshold:{' '}
            {`${selectedAlert?.requirement?.normal_range_min_custom.sp}`}
          </Typography>
            )
          : null}
        {selectedAlert?.requirement?.normal_range_max?.sp
          ? (
          <Typography sx={{ width: '90%' }}>
            Maximum Systolic Threshold:{' '}
            {`${selectedAlert?.requirement?.normal_range_max_custom.sp}`}
          </Typography>
            )
          : null}
        {selectedAlert?.requirement?.normal_range_min?.dp
          ? (
          <Typography sx={{ width: '90%' }}>
            Minimum Diastolic Threshold:{' '}
            {`${selectedAlert?.requirement?.normal_range_min_custom.dp}`}
          </Typography>
            )
          : null}
        {selectedAlert?.requirement?.normal_range_max?.dp
          ? (
          <Typography sx={{ width: '90%' }}>
            Maximum Diastolic Threshold:{' '}
            {`${selectedAlert?.requirement?.normal_range_max_custom.dp}`}
          </Typography>
            )
          : null}
      </Box>
    </Modal>
  )
}

ViewAlertModal.propTypes = {
  selectedAlert: PropTypes.object,
  setSelectedAlert: PropTypes.func
}

export default ViewAlertModal
