import { makeStyles } from '@mui/styles'

export default makeStyles(() => ({
  root: {
    display: 'flex'
  },
  middleImage: {
    maxHeight: '64px',
    paddingTop: '6px',
    paddingBottom: '6px'
  },
  toolBar: {
    flexDirection: 'row',
    justifyContent: 'center'
  },
  toolBarItems: {
    paddingRight: '8px',
    paddingLeft: '8px'
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  video: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  side: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  roundIcon: {
    width: 50,
    color: '#e9e9e9'
  },
  expand: {
    position: 'absolute',
    zIndex: 10,
    background: 'white',
    display: 'flex',
    backgroundColor: '#333333'
  },
  expandSpeed: {
    position: 'absolute',
    zIndex: 10,
    background: 'white',
    display: 'flex',
    backgroundColor: '#333333',
    color: 'white',
    flexDirection: 'column',
    padding: 10,
    justifyContent: 'space-between'
  },
  cameraControl: {
    position: 'absolute',
    zIndex: 10,
    background: 'white',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#333333'
  },
  active: {
    color: '#f50057'
  },
  sessionHeader: {
    display: 'flex',
    backgroundColor: '#333333',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  }
}))
