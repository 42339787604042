import React, { createContext, useCallback, useEffect, useState } from 'react'
import ClinicvuService from '../services/Clinicvu/Clinicvu.service'
import { Auth } from 'aws-amplify'
import PropTypes from 'prop-types'

export const UserContext = createContext(null)

export const UserContextProvider = (props) => {
  const [me, setMe] = useState(null)

  const fetchMe = useCallback(async () => {
    ClinicvuService.fetchMe().then((response) => {
      if (response.data) {
        setMe(response.data)
      } else {
        Auth.signOut()
      }
    })
  }, [])

  useEffect(() => {
    fetchMe()
  }, [])

  return (
    <UserContext.Provider value={{ me, fetchMe }}>
      {props.children}
    </UserContext.Provider>
  )
}

UserContextProvider.propTypes = {
  children: PropTypes.object
}
