import React from 'react'
import Snackbar from '@mui/material/Snackbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material//Close'
import { snackBarAnchorOrigin } from '../../../utils/constants'
import PropTypes from 'prop-types'

const NotificationPrompt = ({
  snackBarOpen,
  handleCloseSnackBar,
  snackBarMessage
}) => {
  return (
    <Snackbar
      anchorOrigin={snackBarAnchorOrigin}
      open={snackBarOpen}
      autoHideDuration={6000}
      onClose={handleCloseSnackBar}
      message={snackBarMessage}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleCloseSnackBar}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    />
  )
}

NotificationPrompt.propTypes = {
  snackBarOpen: PropTypes.bool,
  handleCloseSnackBar: PropTypes.func,
  snackBarMessage: PropTypes.string
}

export default React.memo(NotificationPrompt)
