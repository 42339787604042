import React from 'react'
import OpenViduVideoComponent from './OvVideo'
import './UserVideo.css'
import { ReportsContext } from '@context/reportsContext'
import PropTypes from 'prop-types'

export const UserVideo = ({ isPublisher, streamManager }) => {
  // eslint-disable-next-line no-unused-vars
  const [width, setWidth] = React.useState(null)
  const [height, setHeight] = React.useState(null)
  const videoRef = React.useRef()
  const divRef = React.useRef()
  const {
    setInboundAudio,
    setoutboundAudio,
    setInboundVideo,
    setOutboundVideo
  } = React.useContext(ReportsContext)

  const updateStats = React.useCallback((report, name) => {
    if (name === 'inbound audio') {
      setInboundAudio(report)
    } else if (name === 'inbound video') {
      setInboundVideo(report)
    } else if (name === 'outbound video') {
      setOutboundVideo(report)
    } else if (name === 'outbound audio') {
      setoutboundAudio(report)
    }
  }, [])

  const getNicknameTag = (shorten = false) => {
    try {
      const data = JSON.parse(streamManager.stream.connection.data)
      const firstName = data.firstName
      const lastName = data.lastName
      const designation = data.designation
      let name
      if (!designation) {
        name = `${firstName} ${lastName}`
      } else {
        name = `${lastName}, ${designation}`
      }
      if (shorten) {
        if (name.length > 15) {
          name = name.substring(0, 15) + '...'
        }
      }
      return name
    } catch {
      return ''
    }
  }

  React.useEffect(() => {
    function handleResize () {
      if (!isPublisher) {
        setHeight(divRef.current.clientHeight)
        setWidth(divRef.current.clientWidth)
      }
    }

    if (!isPublisher & (height === '')) {
      setHeight(divRef.current.clientHeight)
      setWidth(divRef.current.clientWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [streamManager, isPublisher])

  function getConnectionStats () {
    if (isPublisher) {
      return
    }
    if (divRef.current) {
      setHeight(divRef.current.clientHeight)
      setWidth(divRef.current.clientWidth)
    }
    streamManager.stream
      .getRTCPeerConnection()
      .getStats(null)
      .then((stats) => {
        stats.forEach((report) => {
          if (report.type === 'inbound-rtp' && report.kind === 'video') {
            updateStats(report, 'inbound video')
          }
          if (report.type === 'inbound-rtp' && report.kind === 'audio') {
            updateStats(report, 'inbound audio')
          }
          if (report.type === 'outbound-rtp' && report.kind === 'video') {
            updateStats(report, 'outbound video')
          }
          if (report.type === 'outbound-rtp' && report.kind === 'audio') {
            updateStats(report, 'outbound audio')
          }
        })
      })
  }

  React.useEffect(async () => {
    const statsInterval = window.setInterval(getConnectionStats, 10000)
    return () => {
      clearInterval(statsInterval)
    }
  }, [streamManager, updateStats])

  return (
    <>
      <div className={!isPublisher ? 'absolute' : 'flex'}>
        {streamManager !== undefined
          ? (
          <div className="streamcomponent">
            <div className="canvas-wrap" ref={divRef}>
              {!isPublisher && (
                <div
                  id="name"
                  style={{
                    top:
                      videoRef & !isPublisher ? videoRef.current.offsetTop : '',
                    left:
                      videoRef & !isPublisher ? videoRef.current.offsetLeft : ''
                  }}
                >
                  {getNicknameTag()}
                </div>
              )}
              {isPublisher ? getNicknameTag(true) : ''}
              <OpenViduVideoComponent
                ref={videoRef}
                streamManager={streamManager}
                isPublisher={isPublisher}
              />
              {!isPublisher && (
                <img
                  src={process.env.PUBLIC_URL + '/assets/svgs/TeleVULogo.svg'}
                  alt="Televu Logo"
                  className="logo"
                />
              )}
            </div>
          </div>
            )
          : null}
      </div>
    </>
  )
}

UserVideo.propTypes = {
  isPublisher: PropTypes.bool,
  streamManager: PropTypes.object,
  sendPointer: PropTypes.func,
  sendStartDrawing: PropTypes.func,
  sendStopDrawing: PropTypes.func,
  sendDrawing: PropTypes.func,
  sendResetDrawing: PropTypes.func,
  takePhoto: PropTypes.bool,
  setTakePhoto: PropTypes.func,
  addImagesArray: PropTypes.func,
  recordingMode: PropTypes.bool
}
