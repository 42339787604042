import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components'
import {
  AmplifyAuthContainer,
  AmplifyAuthenticator,
  AmplifySignIn
} from '@aws-amplify/ui-react'
import { jwtContext } from '@context/jwtContext'
import { CurrentPortalContextProvider } from '@context/CurrentPortalContext'
import { UserContextProvider } from '@context/UserContext'
import Amplify, { Auth } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import awsconfig from './aws-exports'
import Button from '@mui/material/Button'
import AppRouter from './routes/AppRouter'
import './App.scss'

Amplify.configure(awsconfig)

const AuthStateApp = () => {
  const [authState, setAuthState] = useState()
  const [user, setUser] = useState()
  const [jwt, setJwt] = useState()

  useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState)
      setUser(authData)
    })
  }, [])

  useEffect(() => {
    Auth.currentSession().then((res) => {
      const getIdToken = res.getIdToken()
      const jwt = getIdToken.getJwtToken()
      setJwt(jwt)
    })
  }, [authState])

  const queryClient = new QueryClient()

  return authState === AuthState.SignedIn && user && jwt
    ? (
    <AmplifyAuthenticator>
      <UserContextProvider>
        <QueryClientProvider client={queryClient}>
          <jwtContext.Provider value={jwt}>
            <CurrentPortalContextProvider>
              <AppRouter user={user} jwt={jwt} />
            </CurrentPortalContextProvider>
          </jwtContext.Provider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </UserContextProvider>
    </AmplifyAuthenticator>
      )
    : (
    <>
      <AmplifyAuthContainer>
        <AmplifyAuthenticator>
          <div className="homePage" slot="sign-in">
            <div className="leftPage">
              <p>&nbsp;</p>
              <div className="logos">
                {process.env.REACT_APP_environment_type === 'clinicvu' && (
                  <img src="/assets/clinicvu-logo.svg" alt="Logo" />
                )}
                {process.env.REACT_APP_environment_type === 'biovu' && (
                  <img src="/assets/biovu-logo.svg" alt="Logo" />
                )}
                <img
                  src="/assets/virtual-care-logo.svg"
                  alt="Virtual Care Reimagined"
                />
              </div>
              <p style={{ color: '#8a8b8c', marginBottom: 10 }}>
                © 2021 TeleVU Innovation Ltd. - All Rights Reserved.
              </p>
            </div>
            <div className="rightPage">
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'flex-end'
                }}
              >
                <Button
                  style={{
                    color: 'white',
                    marginRight: 30
                  }}
                  variant="text"
                >
                  English
                </Button>
                <Button
                  style={{
                    color: 'white',
                    marginRight: 10
                  }}
                  variant="text"
                >
                  French
                </Button>
              </div>
              <AmplifySignIn
                slot="sign-in"
                hideSignUp={true}
                headerText="Sign In"
                submitButtonText="Login"
              />
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center'
                }}
              >
                <Button
                  style={{ color: '#193139', marginRight: 30 }}
                  variant="text"
                  href="https://televu.ca/terms-of-use/"
                >
                  Terms and Conditions
                </Button>
                <Button
                  style={{ color: '#193139' }}
                  variant="text"
                  href="https://televu.ca/privacy-policy/"
                >
                  Privacy Policy
                </Button>
              </div>
            </div>
          </div>
        </AmplifyAuthenticator>
      </AmplifyAuthContainer>
    </>
      )
}

export default AuthStateApp
