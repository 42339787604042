import React from 'react'
import {
  resolutionNumbers,
  permissionConstraints
} from '../../../utils/constants'
import {
  isScreenvu,
  getMicrophone,
  getVideo,
  getWebcam
} from '../../../utils/functions'
import CallBar from '@shared/call-bar/CallBar'
import VideoBox from '@shared/video-box/VideoBox'
import { ReportsContextProvider } from '@context/reportsContext'
import { UserContext } from '@context/UserContext'
import PropTypes from 'prop-types'

const VideoCall = ({
  setSnackBarMessage,
  openSnackBar,
  session,
  OV,
  mySessionId,
  mainStreamManager,
  setMainStreamManager,
  endSession,
  sendSignal,
  subscribers,
  updateMainStreamManager
}) => {
  const { me } = React.useContext(UserContext)
  const [time, setTime] = React.useState(null)
  const [foundWebcams, setFoundWebcams] = React.useState(null)
  const [video, setVideo] = React.useState(getVideo(me))
  const [audio, setAudio] = React.useState(getMicrophone(me))
  const [screen, setScreen] = React.useState(false)
  const [cameraSelected, setCameraSelected] = React.useState('')
  const [publisher, setPublisher] = React.useState(null)

  React.useEffect(() => {
    connectToCall()
  }, [])

  const connectToCall = async () => {
    setTime(Date.now())
    updateWebcams()
  }

  const updateWebcams = async () => {
    try {
      const foundWebcams = {}
      await navigator.mediaDevices
        .getUserMedia(permissionConstraints)
        .catch((error) => console.log(error))
      const devices = await OV.getDevices()
      devices.forEach((device) => {
        if (device.kind === 'videoinput') {
          foundWebcams[device.deviceId] = device.label
        }
      })
      setFoundWebcams(foundWebcams)
      const localCameraSelected = getWebcam(me, foundWebcams)
      setCameraSelected(localCameraSelected)
      initPublisher(audio, video, localCameraSelected)
    } catch (error) {
      setSnackBarMessage(
        'Access to your camera was denied by the browser. Check permissions.'
      )
      openSnackBar()
      initPublisher(audio, false, '')
    }
  }

  // eslint-disable-next-line no-unused-vars
  const copyLink = React.useCallback(() => {
    setSnackBarMessage('Copied link to clipboard')
    openSnackBar()
    navigator.clipboard.writeText(
      process.env.REACT_APP_FRONTEND +
        '/session/?join_session_id=' +
        mySessionId
    )
  }, [mySessionId])

  const initPublisher = async (audio, video, cameraSelected) => {
    const mySession = session
    const publisherSettings = {
      audioSource: undefined,
      videoSource: video && cameraSelected !== '' ? cameraSelected : false,
      publishAudio: audio,
      publishVideo: video,
      resolution: me.preferredWebcamResolution
        ? resolutionNumbers[me.preferredWebcamResolution]
        : '1280x720',
      frameRate: me.preferredFrameRate ? me.preferredFrameRate : 30,
      insertMode: 'APPEND',
      mirror: false
    }
    const myPublisher = OV.initPublisher(
      undefined,
      publisherSettings,
      (error) => {
        if (error) {
          console.log('Error', error)
          setSnackBarMessage(
            'Access to your microphone / camera was denied by the browser. Check permissions and whether device is occupied elsewhere.'
          )
          openSnackBar()
          setAudio(false)
          setVideo(false)
          setCameraSelected('')
        } else {
          if (publisher) {
            mySession.unpublish(publisher)
          }
          mySession.publish(myPublisher)
          setPublisher(myPublisher)
          if (video) {
            sendVideoState(String(video))
          }
        }
      }
    )
  }

  const sendVideoState = (value) => {
    if (!isScreenvu(me)) {
      sendSignal('video state', value)
    }
  }

  const turnOnScreenShare = () => {
    const newPublisher = OV.initPublisher(undefined, {
      audioSource: undefined,
      videoSource: 'screen',
      publishAudio: audio,
      publishVideo: true,
      frameRate: 30,
      insertMode: 'APPEND',
      mirror: false
    })

    newPublisher.once('accessAllowed', () => {
      newPublisher.stream
        .getMediaStream()
        .getVideoTracks()[0]
        .addEventListener('ended', () => {
          initPublisher(audio, video, cameraSelected)
          setScreen(false)
          sendVideoState(String(video))
        })

      const mySession = session
      mySession.unpublish(publisher)
      mySession.publish(newPublisher)

      setScreen(true)
      setMainStreamManager(newPublisher)
      setPublisher(newPublisher)
      sendVideoState('true')
    })

    newPublisher.once('accessDenied', () => {
      console.warn('ScreenShare: Access Denied')
    })
  }

  const toggleScreenShare = React.useCallback(() => {
    if (!screen) {
      turnOnScreenShare()
    } else {
      initPublisher(audio, video, cameraSelected)
      setScreen(false)
      sendVideoState(String(video))
    }
  }, [
    screen,
    video,
    turnOnScreenShare,
    initPublisher,
    audio,
    cameraSelected,
    sendVideoState
  ])

  const toggleAudio = React.useCallback(() => {
    if (audio) {
      publisher.publishAudio(false)
    } else {
      initPublisher(true, video, cameraSelected)
    }
    setAudio(!audio)
  }, [audio, video, cameraSelected, publisher, initPublisher])

  const toggleVideoSession = React.useCallback(
    async (e) => {
      await updateWebcams()
      const value = e.target.value
      if (value === 'off') {
        initPublisher(audio, false, '')
        sendVideoState(String(false))
        setCameraSelected('')
        setVideo(false)
      } else {
        setCameraSelected(value)
        setVideo(true)
        initPublisher(audio, true, value)
      }
    },
    [audio, video, updateWebcams, initPublisher, sendVideoState]
  )

  return (
    <ReportsContextProvider>
      <CallBar
        sendSignal={sendSignal}
        toggleScreenShare={toggleScreenShare}
        endSession={endSession}
        audio={audio}
        toggleMuteSession={toggleAudio}
        time={time}
        video={video}
        foundWebcams={foundWebcams}
        toggleVideoSession={toggleVideoSession}
        cameraSelected={cameraSelected}
      />
      <VideoBox
        publisher={publisher}
        handleMainVideoStream={updateMainStreamManager}
        subscribers={subscribers}
        mainStreamManager={mainStreamManager}
      />
    </ReportsContextProvider>
  )
}

VideoCall.propTypes = {
  setSnackBarMessage: PropTypes.func,
  openSnackBar: PropTypes.func,
  session: PropTypes.object,
  OV: PropTypes.object,
  mySessionId: PropTypes.string,
  token: PropTypes.string,
  mainStreamManager: PropTypes.object,
  setMainStreamManager: PropTypes.func,
  endSession: PropTypes.func,
  recordingMode: PropTypes.bool,
  setRecordingMode: PropTypes.func,
  sendSignal: PropTypes.func,
  online: PropTypes.object,
  subscribers: PropTypes.array,
  addGlassvuToCall: PropTypes.func,
  addChatMessage: PropTypes.func,
  messageHistory: PropTypes.array,
  updateMainStreamManager: PropTypes.func,
  addRemotevuToCall: PropTypes.func,
  streamID: PropTypes.string
}

export default React.memo(VideoCall)
