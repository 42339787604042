import React from 'react'
import Button from '@mui/material/Button'
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded'

import { FaMicrophoneAlt, FaMicrophoneAltSlash } from 'react-icons/fa'
import useStyles from './CallBar.style'
import SettingButton from './SettingButton'
import CameraSetting from './CameraSetting'
import PropTypes from 'prop-types'

const CallBar = ({
  endSession,
  audio,
  toggleMuteSession,
  video,
  foundWebcams,
  toggleVideoSession,
  cameraSelected
}) => {
  const classes = useStyles()

  return (
    <div id="session-header" className={classes.sessionHeader}>
      <Button
        variant="contained"
        color="error"
        onClick={endSession}
        endIcon={<ExitToAppRoundedIcon />}
      >
        End
      </Button>
      <SettingButton
        toolTipTitleOn={'Mute'}
        toolTipTitleOff={'Unmute'}
        buttonAction={toggleMuteSession}
        buttonToggleState={audio}
        buttonOnIcon={<FaMicrophoneAlt />}
        buttonOffIcon={<FaMicrophoneAltSlash />}
      />
      <CameraSetting
        video={video}
        foundWebcams={foundWebcams}
        toggleVideoSession={toggleVideoSession}
        cameraSelected={cameraSelected}
      />
    </div>
  )
}

CallBar.propTypes = {
  sendSignal: PropTypes.func,
  handleAddGlassToCallOpen: PropTypes.func,
  handleAddRemoteToCallOpen: PropTypes.func,
  handleAddScreenToCallOpen: PropTypes.func,
  handleAddCamToCallOpen: PropTypes.func,
  handleAddSubscriberOnGlassOpen: PropTypes.func,
  toggleScreenShare: PropTypes.func,
  endSession: PropTypes.func,
  audio: PropTypes.bool,
  toggleMuteSession: PropTypes.func,
  time: PropTypes.number,
  chat: PropTypes.bool,
  toggleChat: PropTypes.func,
  setTakePhoto: PropTypes.func,
  recordingMode: PropTypes.bool,
  sendToggleRecording: PropTypes.func,
  drawerOpen: PropTypes.bool,
  openDrawer: PropTypes.func,
  qrCode: PropTypes.bool,
  toggleQrCode: PropTypes.func,
  flipHorizontalMode: PropTypes.bool,
  toggleFlipHorizontalMode: PropTypes.func,
  screen: PropTypes.bool,
  video: PropTypes.bool,
  foundWebcams: PropTypes.object,
  toggleVideoSession: PropTypes.func,
  cameraSelected: PropTypes.string
}

export default React.memo(CallBar)
