import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class OpenViduVideoComponent extends Component {
  constructor (props) {
    super(props)
    this.videoRef = React.createRef(null)
    this.canvasRef = React.createRef(null)
    this.photoRef = React.createRef(null)
    this.orientation =
      window.innerWidth > window.innerHeight ? 'landscape' : 'portrait'
  }

  blobCallback (props) {
    return function (blob) {
      // eslint-disable-next-line no-unused-vars
      const newImg = document.createElement('img')
      const url = URL.createObjectURL(blob)
      props.addImagesArray(url)
    }
  }

  componentDidUpdate (props) {
    if (props && !!this.videoRef) {
      this.props.streamManager.addVideoElement(this.videoRef.current)
    }
  }

  componentDidMount () {
    if (this.props && !!this.videoRef) {
      this.props.streamManager.addVideoElement(this.videoRef.current)
    }
  }

  render () {
    return (
      <>
        <video
          poster={process.env.PUBLIC_URL + '/assets/pngs/profile.png'}
          id="myVideo"
          autoPlay={true}
          ref={this.videoRef}
          className={
            this.props.isPublisher
              ? 'publisher'
              : this.orientation === 'landscape'
                ? 'lSubscriber'
                : 'pSubscriber'
          }
        />
      </>
    )
  }
}

OpenViduVideoComponent.propTypes = {
  streamManager: PropTypes.object,
  isPublisher: PropTypes.bool,
  takePhoto: PropTypes.bool,
  setTakePhoto: PropTypes.func
}
