import React from 'react'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import { patientModalStyles } from '@utils/constants'
import PropTypes from 'prop-types'

const ViewAppointmentModal = ({
  selectedAppointment,
  setSelectedAppointment
}) => {
  return (
    <Modal
      open={!!selectedAppointment}
      onClose={() => setSelectedAppointment(null)}
      aria-labelledby="Appointment Modal"
      aria-describedby="This modal contains the detailed information regarding the scheduled appointments"
    >
      <Box sx={patientModalStyles}>
        <Typography variant="h4" id="note-details-modal__title">
          Appointment Details
        </Typography>
        <Typography
          id="note-details-modal__sub-date"
          variant="h6"
          component="h2"
        >
          Created at:{' '}
          {`${moment(selectedAppointment?.created_at).format(
            'MMMM Do YYYY, h:mm:ss a'
          )}`}
        </Typography>
        <hr />
        <Typography>Topic: {selectedAppointment?.title}</Typography>
        <Typography>Description: {selectedAppointment?.description}</Typography>
        <Typography>
          Scheduled For:{' '}
          {`${moment
            .unix(selectedAppointment?.start_date_at)
            .format('MMMM Do YYYY, h:mm a')} - ${moment
            .unix(selectedAppointment?.end_date_at)
            .format('MMMM Do YYYY, h:mm a')}`}
        </Typography>
        <Typography>
          Scheduled By:{' '}
          {`${selectedAppointment?.clinicvu_users_on_portal?.clinicvu_user?.last_name}, ${selectedAppointment?.clinicvu_users_on_portal?.clinicvu_user?.designation}`}
        </Typography>
        <Typography sx={{ width: '90%' }}>
          Link:{' '}
          <a
            rel="noreferrer"
            target="_blank"
            href={`/session?join_session_id=${selectedAppointment?.session_id}`}
          >
            Click Here
          </a>
        </Typography>
      </Box>
    </Modal>
  )
}

ViewAppointmentModal.propTypes = {
  selectedAppointment: PropTypes.object,
  setSelectedAppointment: PropTypes.func
}

export default ViewAppointmentModal
