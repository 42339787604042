import React from 'react'
import SettingButton from './SettingButton'
import CameraSelector from './CameraSelector'
import { FaVideo, FaVideoSlash } from 'react-icons/fa'
import PropTypes from 'prop-types'

const CameraSetting = ({
  video,
  foundWebcams,
  toggleVideoSession,
  cameraSelected
}) => {
  const [showCameras, setShowCameras] = React.useState(false)
  return (
    <>
      <SettingButton
        toolTipTitleOn={'Turn Off Video'}
        toolTipTitleOff={'Turn On Video'}
        buttonAction={() => setShowCameras(true)}
        buttonToggleState={video}
        buttonOnIcon={<FaVideo />}
        buttonOffIcon={<FaVideoSlash />}
      />
      {showCameras
        ? (
        <CameraSelector
          setShowCameras={setShowCameras}
          cameraSelected={cameraSelected}
          toggleVideoSession={toggleVideoSession}
          foundWebcams={foundWebcams}
        />
          )
        : null}
    </>
  )
}

CameraSetting.propTypes = {
  video: PropTypes.bool,
  cameraSelected: PropTypes.string,
  toggleVideoSession: PropTypes.func,
  foundWebcams: PropTypes.object
}

export default React.memo(CameraSetting)
