import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import Home from '../components/pages/home/Home'
import PatientDetails from '../components/pages/patient-details/PatientDetails'
import Sessions from '@pages/sessions/Sessions'
import SignOut from '../components/shared/sign-out/SignOut'

const BiovuRoutes = () => {
  return (
    <Routes>
      <Route path="/" exact element={<Navigate replace to="users" />} />
      <Route path="/users" element={<Home />} />
      <Route path="/session" exact element={<Sessions />} />
      <Route path="/user/:patientID" exact element={<PatientDetails />} />
      <Route path="/sign-out" exact element={<SignOut />} />
    </Routes>
  )
}

export default BiovuRoutes
