import React from 'react'
import useStyles from './CallBar.style'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import PropTypes from 'prop-types'

const SettingButton = ({
  toolTipTitleOn,
  toolTipTitleOff,
  iconButtonRef,
  buttonAction,
  buttonToggleState,
  buttonOnIcon,
  buttonOffIcon,
  toolTipPlacement
}) => {
  const classes = useStyles()
  return (
    <Tooltip
      title={
        buttonToggleState
          ? toolTipTitleOn
          : toolTipTitleOff || toolTipTitleOn
      }
      placement={toolTipPlacement}
    >
      <IconButton
        ref={iconButtonRef}
        onClick={buttonAction}
        className={classes.roundIcon}
      >
        {buttonToggleState ? buttonOnIcon : buttonOffIcon}
      </IconButton>
    </Tooltip>
  )
}

SettingButton.propTypes = {
  toolTipTitleOn: PropTypes.string,
  toolTipTitleOff: PropTypes.string,
  iconButtonRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any })
  ]),
  buttonAction: PropTypes.func,
  buttonToggleState: PropTypes.bool,
  buttonOnIcon: PropTypes.node,
  buttonOffIcon: PropTypes.node,
  toolTipPlacement: PropTypes.string
}

export default React.memo(SettingButton)
