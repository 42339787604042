import React, { useEffect, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState } from 'draft-js'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import PostAddIcon from '@mui/icons-material/PostAdd'
import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import styles from './ContactForm.module.scss'
import { convertToHTML } from 'draft-convert'
import PropTypes from 'prop-types'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'fit-content',
  minWidth: '800px',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
}

const ContactForm = ({ open, setOpen, notifyPatient }) => {
  const [selectedCommunicationMethod, setSelectedCommunicationMethod] =
    useState(null)
  const [stateRaw, setStateRaw] = useState(null)
  const [stateHTML, setStateHTML] = useState(null)

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  )

  const handleClose = () => {
    setSelectedCommunicationMethod(null)
    setEditorState(EditorState.createEmpty())
    setStateRaw(null)
    setStateHTML(null)
    setOpen(false)
  }

  const saveContent = () => {
    setStateRaw(editorState.getCurrentContent().getPlainText())
    setStateHTML(convertToHTML(editorState.getCurrentContent()))
  }

  useEffect(() => {
    if (stateRaw && stateHTML) {
      notifyPatient.mutate({ selectedCommunicationMethod, stateRaw, stateHTML })
      handleClose()
    }
  }, [stateRaw, stateHTML])

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <div className={styles.modal__header}>
          <PostAddIcon />
          Notify Patient
        </div>
        <div className={styles.modal__info}>
          <Dropdown
            options={[
              { value: 'email', label: 'Email' },
              { value: 'sms', label: 'Text Message' },
              { value: 'preferred', label: "Patient's Preffered Method" }
            ]}
            placeholder="Select method..."
            onChange={(method) => setSelectedCommunicationMethod(method.value)}
            className={styles.modal__dropdown}
          />
        </div>
        <div className={styles.modal__editor}>
          <Editor
            editorState={editorState}
            onEditorStateChange={setEditorState}
          />
        </div>
        <div className={styles.modal__btns}>
          <button onClick={handleClose}>Cancel</button>
          <button onClick={saveContent} disabled={!selectedCommunicationMethod}>
            Send
          </button>
        </div>
      </Box>
    </Modal>
  )
}

ContactForm.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  notifyPatient: PropTypes.object
}

export default ContactForm
