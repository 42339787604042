import AllPatientsTable from '@shared/all-patients-table/AllPatientsTable'
import EnrollNewPatientForm from '@shared/enroll-new-patient-form/EnrollNewPatientForm'
import TopSection from '@shared/top-section/TopSection'
import React, { useState, useEffect } from 'react'
import styles from './Home.module.scss'
import { useLocation } from 'react-router-dom'

const Home = () => {
  const location = useLocation()
  const [showNew, setShowNew] = useState(false)

  const enroll = () => {
    setShowNew(!showNew)
  }

  useEffect(() => {
    // Reset to list view when location key is updated.
    setShowNew(false)
  }, [location.key])

  return (
    <div className={styles.content}>
      {process.env.REACT_APP_environment_type === 'clinicvu'
        ? (
        <TopSection btnFunction={enroll} showNew={showNew}></TopSection>
          )
        : null}
      {!showNew
        ? (
        <div>
          <AllPatientsTable />
        </div>
          )
        : (
        <div>
          <EnrollNewPatientForm
            setShowNew={setShowNew}
            goBack={() => setShowNew(false)}
          />
        </div>
          )}
    </div>
  )
}

export default Home
