/* eslint-disable no-unused-vars */
/* eslint-disable multiline-ternary */
// todo: refactor ECG logic.
// todo: refactor patient summary into a separate shared component.
// todo: so many states... what's the proper way of handling more complex structures like ours?
import EnrollNewPatientForm from '@shared/enroll-new-patient-form/EnrollNewPatientForm'
import { EmptyGraph } from '@utils/constants'
import React, { useContext, useEffect, useState } from 'react'
import { Bar, Line } from 'react-chartjs-2'
import moment from 'moment'
import ContactForm from '@shared/contact-form/ContactForm'
import TopSection from '@shared/top-section/TopSection'
import {
  formatPhoneNumbers,
  generateDoubleLineGraphData,
  generateECGGraphData,
  generateSingleLineGraphData,
  generateSPO2GraphData,
  capitalize,
  epochToDDMMYY
} from '@utils/functions'
import styles from './PatientDetails.module.scss'
import EcgCanvas from './EcgCanvas'
import { UserContext } from '@context/UserContext'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper
} from '@mui/material'
import NewAppointmentModal from '@shared/new-appointment-modal/NewAppointmentModal'
import NewNotesModal from '@shared/new-notes-modal/NewNotesModal'
import ViewAlertModal from '@shared/view-alert-modal/ViewAlertModal'
import ViewAppointmentModal from '@shared/view-appointment-modal/ViewAppointmentModal'
import ViewNoteModal from '@shared/view-note-modal/ViewNoteModal'
import ViewSurveyResponseModal from '@shared/view-survey-response-modal/ViewSurveyResponseModal'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams, useNavigate } from 'react-router-dom'
import ClinicvuService from 'services/Clinicvu/Clinicvu.service'

// TODO: figure out what "retake" is supposed to do.
// TODO: figure out the deal with days of the week and whether we are going to display them or not.
// TODO: What are the colors associated with each care plan?

const PatientDetails = () => {
  const [editPatient, setEditPatient] = useState(false)

  const [tempData, setTempData] = useState(EmptyGraph)
  const [weightData, setWeightData] = useState(EmptyGraph)
  const [bloodPressureData, setBloodPressureData] = useState(EmptyGraph)
  const [spo2Data, setSpo2Data] = useState(EmptyGraph)
  const [ecgData, setecgData] = useState(EmptyGraph)
  const [surveyGroups, setSurveyGroups] = useState(null)
  const [patientNotes, setPatientNotes] = useState(null)
  const [patientAppointments, setPatientAppointments] = useState(null)

  const [open, setOpen] = useState(false)

  const [isOpenNotesModal, setIsOpenNotesModal] = useState(false)
  const [isOpenAppointmentModal, setIsOpenAppointmentModal] = useState(false)
  const [patientNoteText, setPatientNoteText] = useState('')
  const [patientIsReviewed, setPatientIsReviewed] = useState(null)
  const [selectedAppointment, setSelectedAppointment] = useState(null)

  const [selectedNote, setSelectedNote] = useState(null)
  const [selectedSurveyResponseGroup, setSelectedSurveyResponseGroup] =
    useState(null)
  const [selectedAlert, setSelectedAlert] = useState(null)

  const [ecgSkipCount, setEcgSkipCount] = useState(0)

  const { me } = useContext(UserContext)

  const navigate = useNavigate()

  const queryClient = useQueryClient()

  const { patientID: id } = useParams()

  const closePatientNotesModal = () => {
    setPatientNoteText('')
    setPatientIsReviewed(null)
    setIsOpenNotesModal(false)
  }

  const { data: patient } = useQuery(['patientData', id], async () => {
    const res = await ClinicvuService.getBiovuUserOnPortal(id)
    if (res.status !== 200) {
      throw new Error(`Was not able to fetch patient data with id: ${id}`)
    }
    return res.data
  })

  const { data: ecg } = useQuery(['ecgData', id, ecgSkipCount], async () => {
    const res = await ClinicvuService.getBiovuUserECG(id, ecgSkipCount)
    if (res.status !== 200) {
      throw new Error(
        `Was not able to fetch ECG data with skip count: ${ecgSkipCount}`
      )
    }
    return res.data
  })

  useEffect(() => {
    if (patient) {
      if (patient.bt.length > 0) {
        setTempData(
          generateSingleLineGraphData(patient.bt.reverse(), 'Unit: Celcius')
        )
      }

      if (patient.weight.length > 0) {
        setWeightData(
          generateSingleLineGraphData(patient.weight.reverse(), 'Unit: Kg')
        )
      }

      if (patient.bp.length > 0) {
        setBloodPressureData(
          generateDoubleLineGraphData(
            patient.bp.reverse(),
            'Systollic',
            'Diastollic'
          )
        )
      }

      if (patient.spo2.length > 0) {
        setSpo2Data(
          generateSPO2GraphData(patient.spo2.reverse(), '% Blood Oxygen')
        )
      }

      if (patient.surveyResponses) {
        let responses = patient.surveyResponses.slice()
        responses = responses.reverse()
        setSurveyGroups(responses)
      }

      if (patient?.notes) {
        let notes = patient.notes.slice()
        notes = notes.reverse()
        setPatientNotes(notes)
      }

      if (patient?.appointements) {
        setPatientAppointments(patient.appointements)
      }
    }
  }, [patient])

  useEffect(() => {
    if (ecg?.length > 0) {
      setecgData(generateECGGraphData(ecg))
    } else {
      setecgData(EmptyGraph)
    }
  }, [ecg, editPatient])

  const notifyPatient = useMutation(
    (notification) => {
      ClinicvuService.notifyPatients(
        notification.selectedCommunicationMethod,
        [patient.biovuUser.email],
        notification.stateRaw,
        notification.stateHTML
      )
    },
    {
      onError: (error) => console.log(error)
    }
  )

  const handleNoteSubmit = async () => {
    try {
      await ClinicvuService.addNote(patientNoteText, id, me.id)
      if (patientIsReviewed === true) {
        await ClinicvuService.reviewAlerts(id, me.id)
      }
      queryClient.invalidateQueries('patientData')
      queryClient.invalidateQueries('ecgData')
      setIsOpenNotesModal(false)
    } catch (e) {
      console.error(`Error occurred while updating patient alerts: ${e}`)
    }
  }

  const scheduleAppointment = async (
    topic,
    description,
    startDateAt,
    hourLength,
    minuteLength
  ) => {
    try {
      await ClinicvuService.createAppointment(
        id,
        topic,
        description,
        startDateAt,
        hourLength,
        minuteLength
      )
      queryClient.invalidateQueries('patientData')
      queryClient.invalidateQueries('ecgData')
      setIsOpenAppointmentModal(false)
    } catch (e) {
      console.error(`Error occurred while updating patient alerts: ${e}`)
    }
  }

  return (
    <div className={styles.content}>
      {process.env.REACT_APP_environment_type === 'clinicvu' ? (
        <TopSection
          btnFunction={() => navigate('/patients')}
          showNew={true}
        ></TopSection>
      ) : null}
      {!editPatient ? (
        <>
          <div className={styles['grid-container']}>
            {patient && (
              <div className={styles.card}>
                <div className={styles.card__header}>Patient Details</div>
                <div className={styles['card__content--details']}>
                  <TableContainer component={Paper} sx={{ width: '100%' }}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell>
                            {`${
                              patient.biovuUser?.first_name
                                ? patient.biovuUser?.first_name
                                : 'Unspecified'
                            } 
                                      ${
                                        patient.biovuUser?.last_name
                                          ? patient.biovuUser?.last_name
                                          : 'Unspecified'
                                      }`}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Sex</TableCell>
                          <TableCell>
                            {patient.biovuUser?.sex
                              ? capitalize(patient.biovuUser.sex)
                              : 'Unspecified'}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Date of Birth</TableCell>
                          <TableCell>
                            {patient.biovuUser?.birth_date_at
                              ? `${epochToDDMMYY(
                                  patient.biovuUser.birth_date_at
                                )} (${moment().diff(
                                  patient.biovuUser.birth_date_at,
                                  'years'
                                )} years old)`
                              : 'Unspecified'}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Email</TableCell>
                          <TableCell>
                            {patient.biovuUser?.email
                              ? patient.biovuUser?.email
                              : 'Unspecified'}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Phone Number</TableCell>
                          <TableCell>
                            {patient.biovuUser?.phone
                              ? formatPhoneNumbers(patient.biovuUser?.phone)
                              : 'Unspecified'}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Height</TableCell>
                          <TableCell>
                            {patient.biovuUser?.height
                              ? `${patient.biovuUser?.height} cm`
                              : 'Unspecified'}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            )}
            {patient && patient.alerts && patient.alerts.length !== 0 && (
              <div className={styles.card}>
                <div className={styles.card__header}>Alerts Summary</div>
                <div className={styles['card__content--summary']}>
                  {patient.alerts.map((alert, index) => (
                    <button
                      className={styles['pre-modal-btn']}
                      key={`summary-${index}`}
                      onClick={() => setSelectedAlert(alert)}
                    >
                      {alert.issue} on {moment(alert.alert_at).format('DD-MMM')}
                    </button>
                  ))}
                </div>
              </div>
            )}

            {patient && patient.biovuUsersOnPortalsOnCarePlans && (
              <div className={styles.card}>
                <div className={styles.card__header}>Care Plans</div>
                <div className={styles['card__content--summary']}>
                  {patient.biovuUsersOnPortalsOnCarePlans.map(
                    (carePlan, index) => (
                      <div
                        key={`summary-${index}`}
                        className={styles['care-plan']}
                      >
                        <div
                          className={styles['care-plan__icon']}
                          style={{
                            backgroundColor: carePlan.care_plan?.color
                          }}
                        ></div>
                        <span className={styles['care-plan__text']}>
                          {carePlan.care_plan?.name}
                        </span>
                      </div>
                    )
                  )}
                  {process.env.REACT_APP_environment_type === 'clinicvu' && (
                    <button
                      className={styles.btn}
                      onClick={() => setEditPatient(true)}
                    >
                      Change patient plans
                    </button>
                  )}
                </div>
              </div>
            )}

            {patient &&
              process.env.REACT_APP_environment_type === 'clinicvu' && (
                <div className={styles.card}>
                  <div className={styles.card__header}>Administration</div>
                  <div className={styles['card__content--contact']}>
                    <button
                      className={styles['btn--primary']}
                      onClick={() => setOpen(true)}
                    >
                      Contact Patient
                    </button>
                    <button
                      className={styles['btn--primary']}
                      onClick={() => setIsOpenNotesModal(true)}
                    >
                      Add Note
                    </button>
                    <button
                      className={styles['btn--primary']}
                      onClick={() => setIsOpenAppointmentModal(true)}
                    >
                      Schedule Appointment
                    </button>
                  </div>
                </div>
            )}
            {patient && tempData.labels.length !== 0 && (
              <div className={styles.card}>
                <div className={styles.card__header}>Temperature</div>
                <div className={styles.card__content}>
                  <Line data={tempData} options={{ responsive: true }} />
                </div>
              </div>
            )}
            {patient && bloodPressureData.labels.length !== 0 && (
              <div className={styles.card}>
                <div className={styles.card__header}>Blood Pressure</div>
                <div className={styles.card__content}>
                  <Line
                    data={bloodPressureData}
                    options={{ responsive: true }}
                  />
                </div>
              </div>
            )}
            {patient && spo2Data.labels.length !== 0 && (
              <div className={styles.card}>
                <div className={styles.card__header}>SpO2</div>
                <div className={styles.card__content}>
                  <Bar data={spo2Data} options={{ responsive: true }} />
                </div>
              </div>
            )}

            {patient && weightData.labels.length !== 0 && (
              <div className={styles.card}>
                <div className={styles.card__header}>Weight</div>
                <div className={styles.card__content}>
                  <Line data={weightData} options={{ responsive: true }} />
                </div>
              </div>
            )}

            {patient && patientNotes && patientNotes.length !== 0 && (
              <div className={styles.card}>
                <div className={styles.card__header}>Notes</div>
                <div className={styles['card__content--summary']}>
                  {patientNotes?.map((note, index) => (
                    <button
                      className={styles['pre-modal-btn']}
                      key={`note-${index}`}
                      onClick={() => setSelectedNote(note)}
                    >{`${
                      note?.clinicvu_user_on_portals?.clinicvu_user
                        ?.last_name || 'NA'
                    } - ${moment(note.created_at).format(
                      'MMM Do YY'
                    )}`}</button>
                  ))}
                </div>
              </div>
            )}

            {patient && surveyGroups && surveyGroups.length !== 0 && (
              <div className={styles.card}>
                <div className={styles.card__header}>Survey Responses</div>
                <div className={styles['card__content--summary']}>
                  {surveyGroups?.map((surveyResponseGroup, index) => (
                    <button
                      className={styles['pre-modal-btn']}
                      key={`survey-response-${index}`}
                      onClick={() =>
                        setSelectedSurveyResponseGroup(surveyResponseGroup)
                      }
                    >{`${surveyResponseGroup?.survey?.name || 'NA'} - ${moment(
                      surveyResponseGroup?.created_at
                    ).format('MMM Do YY, h:mm a')}`}</button>
                  ))}
                </div>
              </div>
            )}
            {patient &&
              patientAppointments &&
              patientAppointments.length !== 0 && (
                <div className={styles.card}>
                  <div className={styles.card__header}>Appointments</div>
                  <div className={styles['card__content--summary']}>
                    {patientAppointments
                      .sort((a, b) =>
                        a.start_date_at > b.start_date_at ? 1 : -1
                      )
                      .map((patientAppointment, index) => (
                        <button
                          className={styles['pre-modal-btn']}
                          key={`survey-response-${index}`}
                          onClick={() =>
                            setSelectedAppointment(patientAppointment)
                          }
                        >{`${patientAppointment?.title || 'NA'} - ${moment
                          .unix(patientAppointment?.start_date_at)
                          .format('MMM Do YY, h:mm a')} - ${moment
                          .unix(patientAppointment?.end_date_at)
                          .format('h:mm a')}`}</button>
                      ))}
                  </div>
                </div>
            )}
          </div>
          {patient && ecgData && Object.keys(ecgData).length > 2 && (
            <div className={`${styles.card} ${styles.ecg}`}>
              <div>
                <button
                  className={styles['btn--primary']}
                  disabled={ecgSkipCount === patient?.ecgCount - 1}
                  onClick={() => setEcgSkipCount(ecgSkipCount + 1)}
                >
                  Previous
                </button>
                <button
                  className={styles['btn--primary']}
                  disabled={ecgSkipCount === 0}
                  onClick={() => setEcgSkipCount(ecgSkipCount - 1)}
                >
                  Next
                </button>
              </div>
              {ecg?.length > 0 && (
                <div className={styles.card__header}>
                  ECG from{' '}
                  {moment(ecg[0]?.taken_at).format('MMMM Do YYYY, h:mm a')}
                </div>
              )}
              <div className={styles.card__content}>
                <EcgCanvas ecgData={ecgData} />
              </div>
            </div>
          )}
        </>
      ) : (
        <EnrollNewPatientForm
          setShowNew={setEditPatient}
          goBack={() => setEditPatient(false)}
          currPatient={patient}
        />
      )}

      <ContactForm
        open={open}
        setOpen={setOpen}
        notifyPatient={notifyPatient}
      />

      <NewNotesModal
        isOpenNotesModal={isOpenNotesModal}
        setIsOpenNotesModal={setIsOpenNotesModal}
        me={me}
        patientNoteText={patientNoteText}
        patientIsReviewed={patientIsReviewed}
        setPatientIsReviewed={setPatientIsReviewed}
        handleNoteSubmit={handleNoteSubmit}
        closePatientNotesModal={closePatientNotesModal}
        setPatientNoteText={setPatientNoteText}
      />

      <ViewNoteModal
        selectedNote={selectedNote}
        setSelectedNote={setSelectedNote}
      />

      <ViewSurveyResponseModal
        selectedSurveyResponseGroup={selectedSurveyResponseGroup}
        setSelectedSurveyResponseGroup={setSelectedSurveyResponseGroup}
      />

      <ViewAlertModal
        selectedAlert={selectedAlert}
        setSelectedAlert={setSelectedAlert}
      />

      <ViewAppointmentModal
        selectedAppointment={selectedAppointment}
        setSelectedAppointment={setSelectedAppointment}
      />

      <NewAppointmentModal
        isOpenAppointmentModal={isOpenAppointmentModal}
        setIsOpenAppointmentModal={setIsOpenAppointmentModal}
        me={me}
        patient={patient}
        scheduleAppointment={scheduleAppointment}
      />
    </div>
  )
}

export default PatientDetails
