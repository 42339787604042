import React from 'react'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import { patientModalStyles } from '@utils/constants'
import Button from '@mui/material/Button'
import PropTypes from 'prop-types'
import LooksOneRoundedIcon from '@mui/icons-material/LooksOneRounded'
import LooksTwoRoundedIcon from '@mui/icons-material/LooksTwoRounded'
import Looks3RoundedIcon from '@mui/icons-material/Looks3Rounded'
import Looks4RoundedIcon from '@mui/icons-material/Looks4Rounded'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import styles from './NewAppointmentModal.module.scss'

const NewAppointmentModal = ({
  patient,
  isOpenAppointmentModal,
  setIsOpenAppointmentModal,
  me,
  scheduleAppointment
}) => {
  const [topic, setTopic] = React.useState('')
  const [description, setDescription] = React.useState('')
  const [startDateAt, setStartDateAt] = React.useState(dayjs().add(1, 'day'))
  const [hourLength, setHourLength] = React.useState(1)
  const [minuteLength, setMinuteLength] = React.useState(0)
  return (
    <Modal
      open={isOpenAppointmentModal}
      onClose={() => setIsOpenAppointmentModal(false)}
      aria-labelledby="Patient New Appointment Modal"
      aria-describedby="This modal allows the clinician to add appointments for the current patient"
    >
      <Box sx={patientModalStyles}>
        <Typography id="new-notes-modal__title">
          Schedule Appointment
        </Typography>
        <Typography id="new-notes-modal__sub" variant="h6" component="h2">
          {me?.last_name}, {me?.designation} | {patient?.biovuUser?.last_name}
        </Typography>
        <hr />
        <div className={styles.modal__row}>
          <LooksOneRoundedIcon fontSize="large" />
          <Typography sx={{ width: '20%' }}>Topic</Typography>
          <TextField
            id="filled-basic"
            label="Enter here"
            variant="filled"
            sx={{ width: '95%' }}
            onChange={(e) => setTopic(e.target.value)}
          />
        </div>
        <div className={styles.modal__row}>
          <LooksTwoRoundedIcon fontSize="large" />
          <Typography sx={{ width: '20%' }}>Description (Optional)</Typography>
          <TextField
            id="filled-basic"
            label="Enter here"
            variant="filled"
            multiline
            rows={4}
            sx={{ width: '95%' }}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div className={styles.modal__row}>
          <Looks3RoundedIcon fontSize="large" />
          <Typography sx={{ width: '20%' }}>When</Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              renderInput={(props) => (
                <TextField sx={{ width: '95%' }} {...props} />
              )}
              label="Appointment Time"
              value={startDateAt}
              onChange={(newValue) => {
                setStartDateAt(newValue)
              }}
            />
          </LocalizationProvider>
        </div>
        <div className={styles.modal__row}>
          <Looks4RoundedIcon fontSize="large" />
          <Typography sx={{ width: '20%' }}>Duration</Typography>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={hourLength}
            label="Age"
            sx={{ width: '44%' }}
            onChange={(e) => setHourLength(e.target.value)}
          >
            <MenuItem value={0}>0</MenuItem>
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
          </Select>
          <Typography>hr</Typography>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={minuteLength}
            label="Age"
            sx={{ width: '44%' }}
            onChange={(e) => setMinuteLength(e.target.value)}
          >
            <MenuItem value={0}>0</MenuItem>
            <MenuItem value={15}>15</MenuItem>
            <MenuItem value={30}>30</MenuItem>
            <MenuItem value={45}>45</MenuItem>
          </Select>
          <Typography>min</Typography>
        </div>
        <div className={styles.modal__buttons}>
          <Button
            variant="outlined"
            color="error"
            onClick={() => setIsOpenAppointmentModal(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            sx={{ marginLeft: 1 }}
            disabled={!topic || topic.length === 0}
            onClick={() =>
              scheduleAppointment(
                topic,
                description,
                startDateAt,
                hourLength,
                minuteLength
              )
            }
          >
            Schedule
          </Button>
        </div>
      </Box>
    </Modal>
  )
}

NewAppointmentModal.propTypes = {
  isOpenAppointmentModal: PropTypes.bool,
  setIsOpenAppointmentModal: PropTypes.func,
  me: PropTypes.object,
  patient: PropTypes.object,
  scheduleAppointment: PropTypes.func
}

export default NewAppointmentModal
