import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

const EcgCanvas = ({ ecgData }) => {
  const backgroundRef = useRef(null)
  const waveRef = useRef(null)

  useEffect(() => {
    drawCanvases(backgroundRef, waveRef, ecgData)
  }, [backgroundRef.current, waveRef.current, ecgData])

  return (
    <div
      style={{
        position: 'relative',
        height: 350,
        overflow: 'scroll'
      }}
    >
      <canvas style={{ position: 'absolute' }} ref={backgroundRef} />
      <canvas style={{ position: 'absolute' }} ref={waveRef} />
    </div>
  )
}

const drawCanvases = (backgroundRef, waveRef, ecgData) => {
  const canvas = backgroundRef.current
  if (!canvas) {
    return
  }
  const smallGridColor = '#eeb8ae'
  const largeGridColor = '#E0816F'
  const viewWidth = 5500
  const viewHeight = 350
  const dataPerSec = 512
  const halfViewWidth = viewWidth / 2
  const halfViewHeight = viewHeight / 2

  canvas.width = viewWidth
  canvas.height = viewHeight

  const dpi = window.devicePixelRatio

  // convert to inch using 96 dpi
  const viewWidthInches = viewWidth / (96 * dpi)
  const viewHeightInches = viewHeight / (96 * dpi)

  // convert view to mm
  const viewWidthmm = viewWidthInches * 25.4
  const viewHeightmm = viewHeightInches * 25.4

  const totalLattices = viewWidthmm
  const xS = viewWidth / totalLattices
  const sizeX = viewWidthmm * 0.5
  const sizeY = viewHeightmm * 0.5
  const dataPerLattice = dataPerSec / 25
  const dataSpacing = xS / dataPerLattice

  const getX = (value) => {
    return dataSpacing * value
  }

  const getY = (value) => {
    return halfViewHeight + ((((value * 18.3) / 128) * xS) / 100) * 2
  }

  const ctx = canvas.getContext('2d')

  // draw background
  for (let i = 0; i < sizeX; i++) {
    const x1 = halfViewWidth - i * xS
    const x2 = halfViewWidth + i * xS
    ctx.beginPath()
    if (i % 5 === 0) {
      ctx.strokeStyle = largeGridColor

      ctx.moveTo(x1, 0)
      ctx.lineTo(x1, viewHeight)
      ctx.stroke()

      if (i > 0) {
        ctx.moveTo(x2, 0)
        ctx.lineTo(x2, viewHeight)
        ctx.stroke()
      }
    } else {
      ctx.strokeStyle = smallGridColor

      ctx.moveTo(x1, 0)
      ctx.lineTo(x1, viewHeight)
      ctx.stroke()

      ctx.moveTo(x2, 0)
      ctx.lineTo(x2, viewHeight)
      ctx.stroke()
    }
  }

  for (let i = 0; i < sizeY; i++) {
    const y1 = halfViewHeight - i * xS
    const y2 = halfViewHeight + i * xS
    ctx.beginPath()

    if (i % 5 === 0) {
      ctx.strokeStyle = largeGridColor

      ctx.moveTo(0, y1)
      ctx.lineTo(viewWidth, y1)
      ctx.stroke()

      if (i > 0) {
        ctx.moveTo(0, y2)
        ctx.lineTo(viewWidth, y2)
        ctx.stroke()
      }
    } else {
      ctx.strokeStyle = smallGridColor

      ctx.moveTo(0, y1)
      ctx.lineTo(viewWidth, y1)
      ctx.stroke()

      ctx.moveTo(0, y2)
      ctx.lineTo(viewWidth, y2)
      ctx.stroke()
    }
  }

  if (ecgData.length >= 2) {
    const canvas = waveRef.current
    if (!canvas) {
      return
    }
    canvas.width = viewWidth
    canvas.height = viewHeight
    const ctx = canvas.getContext('2d')
    ctx.strokeStyle = 'black'
    ctx.lineWidth = 1
    for (let i = 0; i < ecgData.length - 1; i++) {
      const dataCurr = ecgData[i]
      const dataNext = ecgData[i + 1]
      const x1 = getX(i)
      const x2 = getX(i + 1)
      const y1 = getY(dataCurr)
      const y2 = getY(dataNext)
      ctx.beginPath()
      ctx.moveTo(x1, y1)
      ctx.lineTo(x2, y2)
      ctx.stroke()
    }
  }
}

EcgCanvas.propTypes = {
  ecgData: PropTypes.array
}

export default EcgCanvas
