import React from 'react'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import { patientModalStyles } from '@utils/constants'
import PropTypes from 'prop-types'

const ViewSurveyResponseModal = ({
  selectedSurveyResponseGroup,
  setSelectedSurveyResponseGroup
}) => {
  return (
    <Modal
      open={!!selectedSurveyResponseGroup}
      onClose={() => setSelectedSurveyResponseGroup(null)}
      aria-labelledby="Survey Response Details Modal"
      aria-describedby="This modal contains the detailed information regarding the currently selected survey response"
    >
      <Box sx={patientModalStyles}>
        <Typography variant="h4" id="note-details-modal__title">
          Survey Reponse Details
        </Typography>
        <Typography
          id="note-details-modal__sub-clinician"
          variant="h6"
          component="h2"
        >
          Survey: {`${selectedSurveyResponseGroup?.survey?.name}`}
        </Typography>
        <Typography
          id="note-details-modal__sub-date"
          variant="h6"
          component="h2"
        >
          Submission Time:{' '}
          {`${moment(selectedSurveyResponseGroup?.created_at).format(
            'MMMM Do YYYY, h:mm:ss a'
          )}`}
        </Typography>
        <hr />

        {selectedSurveyResponseGroup?.responses?.map((response) => (
          <>
            <Typography sx={{ fontWeight: 'bold' }}>
              {`${response.question.text}`}
            </Typography>
            <Typography sx={{ marginBottom: '10px' }}>
              {`${response.response_text}`}
            </Typography>
          </>
        ))}
      </Box>
    </Modal>
  )
}

ViewSurveyResponseModal.propTypes = {
  selectedSurveyResponseGroup: PropTypes.object,
  setSelectedSurveyResponseGroup: PropTypes.func
}

export default ViewSurveyResponseModal
