import React, { createContext, useState, useEffect } from 'react'
import Cookies from 'universal-cookie'
import PropTypes from 'prop-types'

export const CurrentPortalContext = createContext(null)

export const CurrentPortalContextProvider = (props) => {
  const [currentPortal, setCurrentPortal] = useState(0)

  const handlePortalChange = (event) => {
    setCurrentPortal(event.target.value)
    const cookies = new Cookies()
    cookies.set('lastPortal', event.target.value, { path: '/' })
  }

  const loadPortalCookie = () => {
    const cookies = new Cookies()
    const lastPortal = cookies.get('lastPortal')
    if (lastPortal) {
      setCurrentPortal(lastPortal)
    } else {
      setCurrentPortal(0)
    }
  }

  useEffect(() => {
    loadPortalCookie()
  }, [])
  return (
    <CurrentPortalContext.Provider
      value={{
        handlePortalChange,
        currentPortal
      }}
    >
      {props.children}
    </CurrentPortalContext.Provider>
  )
}

CurrentPortalContextProvider.propTypes = {
  children: PropTypes.object
}
