import { clinicvuInstance } from '../axiosService'

class ClinicvuService {
  async deleteCarePlan (portal, name) {
    return clinicvuInstance.delete('/careplans/', {
      portal,
      name
    })
  }

  async getCarePlans (portal) {
    return await clinicvuInstance.get('/careplans/portal/' + portal)
  }

  async getClinicvuUsersOnPortal (currentPortalName) {
    return await clinicvuInstance.get(
      '/clinicvuusers/portal/' + currentPortalName
    )
  }

  async getBiovuUsersOnPortal (currentPortalName, searchTerm) {
    if (process.env.REACT_APP_environment_type === 'clinicvu') {
      return await clinicvuInstance.get(
        `/biovuusers/portal/${currentPortalName}/${
          searchTerm || 'NO_SEARCH_PARAM'
        }`
      )
    } else {
      return await clinicvuInstance.get('/biovuusers')
    }
  }

  async getBiovuUserOnPortal (biovuUserId) {
    if (process.env.REACT_APP_environment_type === 'clinicvu') {
      return await clinicvuInstance.get(
        '/biovuusers/biovuuseronportal/' + biovuUserId
      )
    } else {
      return await clinicvuInstance.get('/biovuusers/biovuuser/' + biovuUserId)
    }
  }

  async getBiovuUserECG (id, skipCount) {
    if (process.env.REACT_APP_environment_type === 'clinicvu') {
      return await clinicvuInstance.get(
        `/biovuusers/ecg/biovuuseronportal/${id}/${skipCount}`
      )
    } else {
      return await clinicvuInstance.get(
        `/biovuusers/ecg/biovuuser/${id}/${skipCount}`
      )
    }
  }

  async notifyPatients (mode, users, messageRaw, messageHTML) {
    return await clinicvuInstance.post('/notifications/', {
      mode,
      users,
      messageRaw,
      messageHTML
    })
  }

  async reviewAlerts (biovuUserId, clinicvuUserId) {
    return await clinicvuInstance.post('/biovuusers/review-alerts', {
      biovuUserId,
      clinicvuUserId
    })
  }

  async addNote (text, biovuUserId, clinicvuUserId) {
    return await clinicvuInstance.post('/biovuusers/add-note', {
      text,
      biovuUserId,
      clinicvuUserId
    })
  }

  async getAllSurveys (currentPortalName) {
    return await clinicvuInstance.get('/surveys/portal/' + currentPortalName)
  }

  async deleteSurvey (portal, name) {
    return await clinicvuInstance.delete('/surveys/', { portal, name })
  }

  async createCarePlan (name, color, requirements, portal) {
    return await clinicvuInstance.post('/careplans/', {
      name,
      color,
      requirements,
      portal
    })
  }

  async createNewBiovuUser (
    email,
    phoneNumber,
    healthNumber,
    selectedCarePlans,
    portal,
    firstName,
    lastName,
    dateOfBirth,
    sex
  ) {
    return await clinicvuInstance.post('/biovuusers/biovuuser', {
      email,
      phoneNumber,
      healthNumber,
      selectedCarePlans,
      portal,
      firstName,
      lastName,
      dateOfBirth,
      sex
    })
  }

  async createNewClinicvuUser (
    firstName,
    lastName,
    portal,
    email,
    role,
    selectedCarePlans,
    userId
  ) {
    return await clinicvuInstance.post('/clinicvuusers/', {
      firstName,
      lastName,
      portal,
      email,
      role,
      selectedCarePlans,
      userId
    })
  }

  async getSurvey (portal, surveyName) {
    return await clinicvuInstance.get(`/surveys/portal/${portal}/${surveyName}`)
  }

  async createSurvey (portal, name, questions, language) {
    return await clinicvuInstance.post('/surveys/', {
      portal,
      name,
      questions,
      language
    })
  }

  async fetchMe () {
    if (process.env.REACT_APP_environment_type === 'clinicvu') {
      return await clinicvuInstance.get('/clinicvuusers/whoami')
    } else {
      return await clinicvuInstance.get('/biovuusers/whoami')
    }
  }

  async fetchAllPortals () {
    return await clinicvuInstance.get('/portals')
  }

  async createPortal (name, licenses, timezone) {
    return await clinicvuInstance.post('/portals', {
      name,
      licenses,
      timezone
    })
  }

  async createAppointment (
    biovuUsersOnPortalsId,
    topic,
    description,
    startDateAt,
    hourLength,
    minuteLength
  ) {
    return await clinicvuInstance.post('/appointments', {
      biovuUsersOnPortalsId,
      topic,
      description,
      startDateAt,
      hourLength,
      minuteLength
    })
  }

  async joinSession (clientData, sessionName) {
    return await clinicvuInstance.post('/sessions', {
      clientData,
      sessionName
    })
  }

  async leaveSession (sessionName, token) {
    return await clinicvuInstance.post('/sessions/leave-session', {
      sessionName,
      token
    })
  }
}

export default new ClinicvuService()
